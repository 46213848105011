<template>
  <div class="py-0" style="right: 0; left: 0">
    <!-- <b-navbar type="dark" variant="primary" toggleable> -->
    <nav class="navbar navbar-expand-lg navbar-custom py-0">
      <b-navbar-toggle target="navbar-toggle-collapse">
        <template #default="{ expanded }">
          <i v-if="expanded" class="fa fa-bars fa-lg py-1 text-white"></i>
          <i v-else class="fa fa-times fa-lg py-1 text-white"></i>
        </template>
      </b-navbar-toggle>
      <!-- <div class="collapse navbar-collapse" id="navbarCustom"> -->
      <b-collapse id="navbar-toggle-collapse" is-nav>
        <ul class="navbar-nav">
          <li class="nav-item" v-if="RoleID != 0">
            <router-link class="nav-link" to="/dashboard">HOME</router-link>
          </li>
           <div class="dropdown" v-if="RoleID==99">
            <button class="nav-item dropbtn">
              Guard Registration
              <i class="fa fa-caret-down" ></i>
            </button>
            <div class="dropdown-content">
            <router-link 
                to="/Applicationstest"
                class="dropdown-item"
                >Application List</router-link
              >
              <router-link 
                to="/registration"
                class="dropdown-item"
                >New Login</router-link
              >
                <router-link 
                to="/registrationlist"
                class="dropdown-item"
                >SignUp List</router-link
              >
                 <router-link
                to="/editduedate"
                class="dropdown-item"
                >Edit Login User</router-link
              >
                <router-link
                to="/applicantwiselist"
                class="dropdown-item"
                >Applicant Wise Liist</router-link
              >
            </div>
           </div>
          <div class="dropdown" v-else>
            <button class="nav-item dropbtn" v-if="RoleID > 0 && RoleID <=4">
              Guard Registration
              <i class="fa fa-caret-down" ></i>
            </button>
            <div class="dropdown-content">
              <router-link 
                to="/applications"
                class="dropdown-item"
                >Application List</router-link
              >
              <router-link
                to="/batchschedulelist"
                class="dropdown-item"
                >Schedule Batch</router-link
              >
              <router-link
                to="/generateresult"
                class="dropdown-item"
                >Generate Result</router-link
              >
              <router-link
                to="/IntimatePoliceMedical"
                class="dropdown-item"
                >Send Intimation Letter</router-link
              >
              <router-link
                to="/eventlist"
                class="dropdown-item"
                >Create New Pool</router-link
              >
              <router-link
                to="/profiles"
                class="dropdown-item"
                >View User Profiles</router-link
              >
              <!-- <router-link 
                to="/registration"
                class="dropdown-item"
                >New Login</router-link
              > -->
              <router-link
                to="/registrationlist"
                class="dropdown-item"
                >SignUp List</router-link
              >
               <router-link
                to="/editduedate"
                class="dropdown-item"
                >Edit Login User</router-link
              >
                 <router-link
                to="/applicantwiselist"
                class="dropdown-item"
                >Applicant Wise Liist</router-link
              >
            </div>
          </div>
           <!-- <div  class="dropdown">
            <button class="nav-item dropbtn">
              Employer Registration
              <i class="fa fa-caret-down"></i>
            </button>
            <div class="dropdown-content">
              <router-link to="/ApplicationList" class="dropdown-item"
                >Application List</router-link
              >
            </div>
          </div> -->
          
          <li class="nav-item"  v-if="RoleID!=99">
            <router-link class="nav-link" to="/PrivateRegistration" 
              >PRIVATE REGISTRATION</router-link
            >
          </li>

          <div v-if="RoleID = 2 || RoleID == 3 || RoleID == 4 || RoleID == 5 || RoleID == 6 || RoleID == 7" class="dropdown">
            <button class="nav-item dropbtn">
              Exemptions
              <i class="fa fa-caret-down"></i>
            </button>
            <div class="dropdown-content"  v-if="RoleID!=99">
              <router-link to="/exemption" class="dropdown-item"
                >Exemption List</router-link
              >
            </div>
          </div>
          <div v-if="RoleID = 2 || RoleID == 3 || RoleID == 4 || RoleID == 5 || RoleID == 6 || RoleID == 7" class="dropdown">
            <button class="nav-item dropbtn">
              REPORTS
              <i class="fa fa-caret-down"></i>
            </button>
           
            <div class="dropdown-content" v-if="RoleID!=99">
              <router-link to="/scrollreport" class="dropdown-item"
                >Scroll Report</router-link
              >
              <router-link to="/PoliceMedicalExemption" class="dropdown-item"
                >Conditional Pass Police & Medical Report</router-link
              >
               <router-link to="/GroundExemption" class="dropdown-item"
                >Conditional Pass Physical/Ground Report</router-link
              >
            </div>
             
          </div>

        </ul>

        <span style="margin-left: auto; margin-right: 25px">
          <ul class="navbar-nav">
            <!-- <li class="nav-brand">
              <a class="nav-link" href="#">WELCOME {{ username }}</a>
            </li> -->
            <div class="dropdown">
              <button class="nav-item dropbtn">
                WELCOME {{ username }}
                <i class="fa fa-caret-down"></i>
              </button>
              <div class="dropdown-content">
                <router-link to="/changePassword" class="dropdown-item"
                  >CHANGE PASSWORD</router-link
                >
              </div>
            </div>
            <li class="nav-item dropdown" style="border-left: 1px solid #fff">
              <a class="nav-link" href="#" @click="logout">LOGOUT</a>
            </li>
          </ul>
        </span>
      </b-collapse>
      <!-- </div> -->
    </nav>
    <!-- </b-navbar> -->
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
      this.$router.replace("/");
    },
    login() {
      this.$router.replace("/");
    },
    setLanguage() {
      var temp_lang = document.getElementsByClassName("goog-te-combo")[0].value;
      var flang =
        temp_lang == "hi" ? "हिन्दी" : temp_lang == "mr" ? "मराठी" : "English";

      document.querySelector(".lang-bar a.dropdown-toggle").innerHTML = flang;
    },
  },
  computed: {
    username() {
      var nme = this.$store.getters.loggedUser;
      return nme.UserName;
    },
    RoleID() {
      var nme = this.$store.getters.loggedUser;
      return nme.RoleID;
      //alert(nme.RegistrationType);
    },
    LockProfile() {
      var lck = this.$store.getters.lockProfile;
      return lck;
    },
    isLOI() {
      var LOIStatus = this.$store.getters.getAppStatus;
      if (
        LOIStatus == 6 ||
        LOIStatus == 8 ||
        LOIStatus == 9 ||
        LOIStatus == 21 ||
        LOIStatus == 22
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style scoped>
.navbar-custom {
  background-color: #2e4b90 !important;
  min-height: 70px;
  font-style: normal;
  font-variant-ligatures: normal;
  font-variant-caps: normal;
  font-variant-numeric: normal;
  font-variant-east-asian: normal;
  font-weight: 500;
  font-stretch: normal;
  font-size: 14px;
  line-height: 54px;
  font-family: "Open sans", sans-serif;
  text-transform: uppercase;
}
/* change the brand and text color */
.navbar-custom .navbar-brand,
.navbar-custom .navbar-text {
  color: white;
}
/* change the link color */
.navbar-custom .navbar-nav .nav-link {
  color: white;
  font-style: normal;
  font-variant-ligatures: normal;
  font-variant-caps: normal;
  font-variant-numeric: normal;
  font-variant-east-asian: normal;
  font-weight: 500;
  font-stretch: normal;
  font-size: 14px;
  font-family: "Open sans", sans-serif;
  padding-left: 30px;
  text-transform: uppercase;
}

/* change the color of active or hovered links */
.navbar-custom .nav-item.active .nav-link,
.navbar-custom .nav-item:hover .nav-link {
  color: #f4ff60;
  padding-left: 30px;
}

.navbar-custom .dropdown-menu {
  background-color: #2e4b90 !important;
  margin-left: -50px;
}
.navbar-custom .dropdown-menu-left {
  background-color: #2e4b90 !important;
  margin-left: 10px !important;
}
.navbar-custom .dropdown-item {
  color: #ffffff;
  height: 40px !important;
  font-style: normal;
  font-variant-ligatures: normal;
  font-variant-caps: normal;
  font-variant-numeric: normal;
  font-variant-east-asian: normal;
  font-weight: 500;
  font-stretch: normal;
  font-size: 14px;
  font-family: "Open sans", sans-serif;
  line-height: 34px;
  padding-left: 30px;
  text-transform: uppercase;
}

.dropdown-item a {
  color: #ffffff;
  height: 40px !important;
  font-style: normal;
  font-variant-ligatures: normal;
  font-variant-caps: normal;
  font-variant-numeric: normal;
  font-variant-east-asian: normal;
  font-weight: 500;
  font-stretch: normal;
  font-size: 14px;
  font-family: "Open sans", sans-serif;
  line-height: 34px;
  padding-left: 30px;
  text-transform: uppercase;
}
.navbar-custom .dropdown-item:hover,
.navbar-custom .dropdown-item:focus {
  /* color: #333333;
  background-color: rgba(255, 255, 255, 0.5); */
  color: black;
  background-color: white;
  display: block;
}

/* Dropdown Button */
.navbar-custom .dropbtn {
  height: 70px;
  background-color: #2e4b90 !important;
  color: white;
  font-style: normal;
  font-variant-ligatures: normal;
  font-variant-caps: normal;
  font-variant-numeric: normal;
  font-variant-east-asian: normal;
  font-weight: 500;
  font-stretch: normal;
  font-size: 14px;

  font-family: "Open sans", sans-serif;
  border: none;
  padding-left: 30px;
  text-transform: uppercase;
}

/* The container <div> - needed to position the dropdown content */
.navbar-custom .dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #2e4b90;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: white;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #ddd;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn:hover {
  background-color: #f4ff60;
}

.header_aera .navbar-collapse .navbar-nav.navbar-right li a {
  margin-top: -15px;
}
</style>