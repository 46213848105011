<template>
  <b-container>
    <!-- <div id="app container">
    <Breadcrumb class="row justify-content-center mt-4" :crumbs="crumbs" @selected="selected" />
  </div> -->
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item" style="font-size: 0.875rem">
          <router-link to="/dashboard">Home</router-link>
        </li>
        <li class="breadcrumb-item" style="font-size: 0.875rem">
          <router-link to="/dasboard">Guard Registration</router-link>
        </li>
        <li
          class="breadcrumb-item active"
          style="font-size: 0.875rem"
          aria-current="page"
        >
         Edit Login User
        </li>
      </ol>
    </nav>

    <ViewProfileFilter
      v-model="form"
      :boards="boards"
      :approvalStatus="approvalStatus"
      :events="events"
      :name="Applications_name"
      @submit="onSubmit"
      @reset="onReset"
    ></ViewProfileFilter>

    <hr />
    <b-row class="mb-2">
      <b-col md="3">
        <b-input-group>
          <b-form-input
            v-model="filter"
            placeholder="Type to Search"
            size="sm"
          />
          <b-input-group-append>
            <b-btn :disabled="!filter" @click="filter = ''" size="sm"
              >Clear</b-btn
            >
          </b-input-group-append>
        </b-input-group>
      </b-col>

      <b-col md="3">
        <p>Total Applications: {{ totalRows }}</p>
      </b-col>
      <b-col>
        <b-button
          class="float-right mx-3"
          variant="outline-danger"
          size="sm"
          @click="convertToPdf"
          style="float: right; font-size: 1em; font-color: red"
        >
          <i class="fa fa-file-pdf"></i>
        </b-button>
        <b-button
          variant="outline-success"
          size="sm"
          @click="downloadExcel"
          style="float: right; font-size: 1em; font-color: green"
        >
          <i class="fa fa-file-excel"></i>
        </b-button>
      </b-col>
    </b-row>
    <loading
      :active.sync="isLoading"
      color="#5dbbda"
      loader="spinner"
      :is-full-page="fullPage"
      width:2
      height:2
    ></loading>
     <b-modal id="confiramation_model" title="Confirmation" hide-footer>
        <p class="my-4">Are You Sure, you want to Resend Email?</p>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" @click="SendMail()">
            OK
          </button>
          <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="CancelModel">
            Cancel
          </button>
        </div>
      </b-modal>
 
<b-modal id="confiramation_log_model" size="xl" type="Submit" title="Log History" hide-footer>

    <div class="body-content ">
      <table
        class="table table-bordered"
        style="text-align: left; horizantal-align: top"
      >

        
        <thead>
        
          <tr>
            <th>Field</th>
            <th>Current Value</th>
            <th>New Value</th>
            <th>Date</th>
            
          </tr>
        </thead> 
       
        <tbody v-for="(value, index) in old_log_form" :key="index">
      
         <tr >
        <td>{{value.FieldName}}</td>
        <td>{{value.OldValue}}</td>
        <td>{{value.NewValue}}</td>
        <td>{{convertDateFormatYMDWithTime(value.EditDate)}}</td>
        </tr>
        </tbody>
       
        
      </table>

    </div>
      </b-modal>
      

      
 <b-modal id="mdlApp" size="xl" type="Submit" title="Edit Application" hide-footer
 
 >

    <div class="body-content ">
     <form @submit.prevent="Submit_profile">
      <table
        class="table table-bordered"
        style="text-align: left; horizantal-align: top"
      >
        <thead>
         <!-- <button type="button" 
         variant="outline-danger"
         class="btn btn-primary" data-dismiss="modal" @click="log_details">
            Log History
          </button> -->
        
        <thead>
        
          <tr>
            <th>Field</th>
            <th>Current Value</th>
            <th>New Value</th>
            
          </tr>
        </thead>
        <tr v-if="eventid=='862ba3c1-7636-4e03-8a1a-485365f577ca'|| eventid=='bb033037-189c-4181-beac-7b3d60aa2bae'">
            <th style="width: 300px">Name of the Employer</th>
             <td >{{old_form.PrincipalEmployerName}}</td>  
            
             <td>  
             <b-form-input
              type="text"
              size="sm"
              id="ipEmpNum" 
              placeholder="Name of the Employer"
              v-model="PrincipalEmployerName"
              onkeypress="return event.charCode >= 65 && event.charCode <= 90 || event.charCode >= 97 && event.charCode <= 122 || event.charCode == 32"
              onpaste="return false"
              ondrop="return false" 
              required  
            /></td>
          </tr>
           <tr v-if="eventid=='862ba3c1-7636-4e03-8a1a-485365f577ca'|| eventid=='bb033037-189c-4181-beac-7b3d60aa2bae'">
            <th style="width: 300px"> Registration No. of the Employer</th>
            <td >{{old_form.EmployerRegNumber}}</td>  
             <td>  
             <b-form-input
              type="text"
              size="sm"
              id="ipEmpNum"  
              placeholder="Registration No. of the Employer" 
              v-model="EmployerRegNumber"  
              onkeypress="return event.charCode >= 48 && event.charCode <= 57  "
              onpaste="return false"
              ondrop="return false"
              required
            /></td>
          </tr>
         <tr >
            <th style="width: 300px"> First Name</th>
            <td >{{old_form.FirstName}}</td>
             <td>  
             <b-form-input
              type="text"
              size="sm"
              id="ipEmpNum" 
               placeholder="First Name" 
              v-model="FirstName"  
              onkeypress="return event.charCode >= 65 && event.charCode <= 90 || event.charCode >= 97 && event.charCode <= 122 || event.charCode == 32"
              onpaste="return false"
              ondrop="return false" 
              required
            /></td>
          </tr>
           <tr>
            <th style="width: 300px"> Middle Name</th>
            <td >{{old_form.MiddleInitial}}</td>
             <td>  
             <b-form-input
              type="text"
              size="sm"
              id="ipEmpNum"
              placeholder="Middle Name"   
              v-model="MiddleInitial"
              onkeypress="return event.charCode >= 65 && event.charCode <= 90 || event.charCode >= 97 && event.charCode <= 122 || event.charCode == 32"
              onpaste="return false"
              ondrop="return false"
              required 
            /></td>
          </tr>
           <tr>
            <th style="width: 300px"> Last Name</th>
            <td >{{old_form.LastName}}</td>
             <td>  
             <b-form-input
              type="text"
              size="sm"
              id="ipEmpNum"
              placeholder="Last Name"  
              v-model="LastName"
              onkeypress="return event.charCode >= 65 && event.charCode <= 90 || event.charCode >= 97 && event.charCode <= 122 || event.charCode == 32"
              onpaste="return false"
              ondrop="return false"
                required  
            /></td>
          </tr>
           <tr >
            <th style="width: 300px">DOB</th>
            <td >{{this.convertDateFormatYMD1(old_form.DOB)}}</td>
          <td>  
             <date-picker 
              placeholder="DOB" 
              v-model="DOB" 
              @change="validateAge"
             :first-day-of-week="1" format="DD-MM-YYYY" lang="en"
             required
             ></date-picker>
             
            </td>
          </tr>
          <tr>
            <th style="width: 300px"> Mobile </th>
            <td >{{old_form.Mobile}}</td>
             <td>  
             <b-form-input
              type="text"
              size="sm"
              id="ipEmpNum"
              placeholder="Mobile"  
              v-model="Mobile" 
              onkeypress="return event.charCode >= 48 && event.charCode <= 57  "
              maxlength="10"
              minlength="10"
              onpaste="return false"
              ondrop="return false"
              required
            /></td>
          </tr>
          <tr>
            <th style="width: 300px"> Email  </th>
            <td >{{old_form.EmailID}}</td>
             <td>  
             <b-form-input
              type="text"
              size="sm"
              id="ipEmpNum"
              placeholder="Email" 
              v-model="EmailID"
              onkeypress="return event.charCode >= 48 && event.charCode <= 57 || 
              event.charCode >= 65 && event.charCode <= 90 || 
              event.charCode >= 97 && event.charCode <= 122 ||
               event.charCode== 64 ||event.charCode== 46 || 
               event.charCode== 95 || event.charCode== 45"
              onpaste="return false"
              ondrop="return false"
              required   
            /></td>
          </tr>
          <tr>
            <th style="width: 300px"> Aadhar No  </th>
            <td >{{old_form.AdharNo}}</td>
             <td>  
             <b-form-input
              type="text"
              size="sm"
              id="ipEmpNum"
              placeholder="Aadhar No" 
              v-model="AdharNo"
              onkeypress="return event.charCode >= 48 && event.charCode <= 57  "
              maxlength="12"
              minlength="12"
              onpaste="return false"
              ondrop="return false"
              required   
            /></td>
          </tr>
          <tr >
          <th>Gender</th>
       <td >{{this.gender_h(old_form.Gender)}}</td>
       <td> 
            <b-form-select  
            placeholder="Gender" 
            v-model="Gender" size="sm" required>
                   <option :value="true">Male</option>
                    <option :value="false">Female</option>
            </b-form-select>
            </td>
          </tr>
          <tr >
            <th>Nationality</th>
         <td >{{this.nationality_h(old_form.Nationality)}}</td>     
          <td>  
            <b-form-radio-group 
            placeholder="Nationality"
            v-model="Nationality"
            required
             name="nationality">
            <b-form-radio value=true>Indian</b-form-radio>
            <b-form-radio value=false>Nepali</b-form-radio>    
      </b-form-radio-group>
           
           </td>
          </tr>
     <!-- ---------------------------------------affec---------------------------------- -->
         
          <!-- -------------------------------------start comp---------------------- -->
          <tr v-if="eventid=='48071941-70d9-4044-85a4-dfb45c8478ca'">
          
            <th style="width: 300px"> Reg. No of Deceased Guard </th>
            <td >{{old_form.DeceasedRegNum}}</td> 
             <td>  
             <b-form-input
              type="text"
              size="sm"
              id="ipEmpNum"
              placeholder="Reg. No of Deceased Guard" 
               onkeypress="return event.charCode >= 65 && event.charCode <= 90 ||
                                event.charCode >= 97 && event.charCode <= 122 ||
                                event.charCode >= 48 && event.charCode <= 57
                                "
               onpaste="return false"
              ondrop="return false"
              v-model="DeceasedRegNum"
              required   
            /></td>
          </tr>
          <tr v-if="eventid=='48071941-70d9-4044-85a4-dfb45c8478ca'">
            <th style="width: 300px">  Name of Deceased Guard   </th>
               <td >{{old_form.DeceasedName}}</td> 
            
             <td>  
             <b-form-input
              type="text"
              size="sm"
              id="ipEmpNum"
              placeholder="Name of Deceased Guard"    
              v-model="DeceasedName"
               onkeypress="return event.charCode >= 65 && event.charCode <= 90 || event.charCode >= 97 && event.charCode <= 122 || event.charCode == 32"
              onpaste="return false"
              ondrop="return false"
              required
            /></td>
          </tr>
           <tr v-if="eventid=='48071941-70d9-4044-85a4-dfb45c8478ca'">
            <th style="width: 300px">  Relationship of the Applicant with Deceased Guard  </th>
             <td >{{old_form.DeceasedRelation}}</td> 
             <td>
              <b-select
              id="ipgndr"
              class="form-control"
              :options="list"
              value-field="RelationID"
              text-field="DeceasedRelation"
              size="sm"
              placeholder="Relationship of the Applicant with Deceased Guard"
              v-model="DeceasedRelation"
              required
            ></b-select>
             </td>
             <!-- <td>  
             <b-form-input
              type="text"
              size="sm"
              id="ipEmpNum" 
              v-model="old_form.DeceasedRelation"   
            /></td> -->
          </tr>
           <tr v-if="eventid=='48071941-70d9-4044-85a4-dfb45c8478ca'">
            <th style="width: 300px">   Legal Heir First Name  </th>
             <td >{{old_form.HeirFirstName}}</td> 
             <td>  
             <b-form-input
              type="text"
              size="sm"
              id="ipEmpNum"
              placeholder="Legal Heir First Name"  
              v-model="HeirFirstName"
               onkeypress="return event.charCode >= 65 && event.charCode <= 90 || event.charCode >= 97 && event.charCode <= 122 || event.charCode == 32"
              onpaste="return false"
              ondrop="return false"
              required  
            /></td>
          </tr>
           <tr v-if="eventid=='48071941-70d9-4044-85a4-dfb45c8478ca'">
            <th style="width: 300px">  Legal Heir Middle Name  </th>
               <td >{{old_form.HeirMiddleName}}</td> 
             <td>  
             <b-form-input
              type="text"
              size="sm"
              id="ipEmpNum"
               placeholder="Legal Heir Middle Name"    
              v-model="HeirMiddleName"
               onkeypress="return event.charCode >= 65 && event.charCode <= 90 || event.charCode >= 97 && event.charCode <= 122 || event.charCode == 32"
              onpaste="return false"
              ondrop="return false"
              required
            /></td>
          </tr>
           <tr v-if="eventid=='48071941-70d9-4044-85a4-dfb45c8478ca'">
            <th style="width: 300px">  Legal Heir Last Name  </th>
               <td >{{old_form.HeirLastName}}</td> 
             <td>  
             <b-form-input
              type="text"
              size="sm"
              id="ipEmpNum"
              placeholder="Legal Heir Last Name"
              v-model="HeirLastName"
               onkeypress="return event.charCode >= 65 && event.charCode <= 90 || event.charCode >= 97 && event.charCode <= 122 || event.charCode == 32"
              onpaste="return false"
              ondrop="return false"
              required    
            /></td>
          </tr>
          <!-- - -------------------------------------end comp---------------------- -- -->
          <!-- ------------------------court start--------------------------- -->
          
           <tr v-if="eventid=='9414312a-b4c3-4dd5-b12c-107cc993ee7f'">
            <th style="width: 300px"> Court Case No</th>
            <td >{{old_form.OrderNum}}</td> 
             <td>  
             <b-form-input
              type="text"
              size="sm"
              id="ipEmpNum"
              placeholder="Court Case No"   
              v-model="OrderNum"
               onkeypress="return event.charCode >= 48 && event.charCode <= 57 ||
               event.charCode >= 65 && event.charCode <= 90 || event.charCode >= 97 && event.charCode <= 122 || event.charCode == 32"
              onpaste="return false"
              ondrop="return false"
              required
            /></td>
          </tr>
           <tr v-if="eventid=='9414312a-b4c3-4dd5-b12c-107cc993ee7f'">
            <th style="width: 300px"> Court Name </th>
             <td >{{old_form.CourtName}}</td> 
            
             <td>  
             <b-form-input
              type="text"
              size="sm"
              id="ipEmpNum"
              placeholder="Court Name"  
              v-model="CourtName"
               onkeypress="return event.charCode >= 65 && event.charCode <= 90 || event.charCode >= 97 && event.charCode <= 122 || event.charCode == 32"
              onpaste="return false"
              ondrop="return false"
              required
            /></td>
          </tr>
           <tr v-if="eventid=='9414312a-b4c3-4dd5-b12c-107cc993ee7f'">
            <th style="width: 300px">Court Order Date</th>
            <td >{{this.convertDateFormatYMD1(old_form.OrderDate)}}</td> 
        
          <td>  
             <date-picker
                      format="DD-MM-YYYY"
                      id="ipBtDt"
                      lang="en"
                      size="small"
                      style=""
                      placeholder="Court Order Date"
                      v-model="OrderDate"
                      required
                      
                 
                ></date-picker>
            </td>
          </tr>
           <tr v-if="eventid=='9414312a-b4c3-4dd5-b12c-107cc993ee7f'">
            <th style="width: 300px"> Party Name </th>
               <td >{{old_form.UnionName}}</td>
             <td>  
             <b-form-input
              type="text"
              size="sm"
              id="ipEmpNum"
              placeholder="Party Name"    
              v-model="UnionName"
               onkeypress="return event.charCode >= 65 && event.charCode <= 90 || event.charCode >= 97 && event.charCode <= 122 || event.charCode == 32"
              onpaste="return false"
              ondrop="return false"
              required
            /></td>
          </tr>
        
          <!-- ------------------------court end--------------------------- -- -->  
        
      </table>
   
      <b-col>
        <b-button
          class="hide-print mt-3 ml-1 float-right"
          size="lg"
          variant="success"
          type="submit"
          >Submit</b-button
        >

      </b-col>
     </form>
    </div>
      </b-modal>
          <b-modal id="editdate" size="xl" type="Submit" title="Edit Details" hide-footer>
         <div class="body-content ">    
    
      <table
        class="table table-bordered"
        style="text-align: left; horizantal-align: top"
      >
        <thead>
        <thead>
          <tr>
            <th>Name</th>
            <th>Current Due Date</th>
            <th>New Due Date</th>
            
          </tr>
        </thead>
        
          <tr >
            <!-- <th style="width: 300px">DOB</th> -->
            <td>{{old_form.FirstName}} {{old_form.MiddleInitial}} {{old_form.LastName}}</td>
             <td >{{this.convertDateFormatYMD1(old_form.FirstLoginBeforeDate)}}</td>
          <td>  
             <date-picker 
            
             v-model="newdue_date" 
             
             :first-day-of-week="1" format="DD-MM-YYYY" lang="en"
             :disabledDate="disableFutureDates"
             ></date-picker>
             
            </td>
          </tr>
      </table>
       <b-col>
        <b-button
          class="hide-print mt-3 ml-1 float-right"
          size="lg"
          variant="info"
          @click="Submit_Date"
          >Submit</b-button
        >
      </b-col>
    </div>
      </b-modal>
      
    <b-table
      id="tblApplication"
      show-empty
      selectable
      select-mode="multi"
      head-variant="dark"
      empty-text="No Profiles Found."
      stacked="md"
      bordered
      striped
      :items="applications"
      :fields="fields"
      :filter="filter"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      @filtered="onFiltered"
      @row-selected="onRowSelected"
      style="font-size: 12px"
    >
      <template v-slot:cell(selected)="{ rowSelected }">
        <!-- <template slot="selected" slot-scope="row"> -->
        <template v-if="rowSelected">
          <span aria-hidden="true">&check;</span>
          <span class="sr-only">Selected</span>
        </template>
        <template v-else>
          <span aria-hidden="true">&nbsp;</span>
          <span class="sr-only">Not Selected</span>
        </template>
      </template>
      <template v-slot:cell(Sr)="row">{{
        (currentPage - 1) * perPage + row.index + 1
      }}</template>
       <template v-slot:cell(sr_no)="row">{{
        (currentPage - 1) * perPage + row.index + 1
      }}</template>
      <!-- <template v-slot:cell(sr_no)="row">
      <div v-if="perPage=='show all'">{{
        (currentPage - 1) * totalRows + row.index + 1
      }}</div>
      <div v-else>
      {{(currentPage - 1) * perPage + row.index + 1}}
      </div>
      
      </template> -->
      <template v-slot:cell(action)="row">
        <b-button
          size="sm"
          @click.stop="viewProfile(row.item)"
          class="mr-1 pt-0 pb-0"
          variant="success"
          v-b-tooltip.hover
          title="View Details"
          >View</b-button
        >
      </template>
        <template v-slot:cell(editapp)="row">
        <b-button 
          size="sm"
          @click.stop="editApplication(row.item)"
          class="mr-1 pt-0 pb-0"
          variant="info"
          v-if="(!row.item.ApplicationNo) && (row.item.ApplicationStatusID == 0 || row.item.ApplicationStatusID == 1 || row.item.ApplicationStatusID == 2 || row.item.ApplicationStatusID == 4)"
        >
          <i class="fas fa-edit"></i>
        </b-button>
      </template>
        <template v-slot:cell(action1)="row"  >
        <b-button 
          size="sm"
          @click.stop="editDate(row.item)"
          class="mr-1 pt-0 pb-0"
          variant="dark"
          v-if="(!row.item.ApplicationNo) && (row.item.ApplicationStatusID == 0 || row.item.ApplicationStatusID == 1 || row.item.ApplicationStatusID == 2 || row.item.ApplicationStatusID == 4)"

        >
          <i class="fas fa-edit"></i>
        </b-button>
        
      </template>
        <template v-slot:cell(loginAction)="row">
        <b-button
          size="sm"
          v-if="!row.item.ApplicationNo"
          @click.stop="ResendEmail(row.item)"
          class="mr-1 pt-0 pb-0"
          variant="success"
        >
          Resend Email
        </b-button>
      </template>
       <template v-slot:cell(logAction)="row">
        <b-button
          size="sm"
          @click.stop="View_log_history(row.item)"
          class="mr-1 pt-0 pb-0"
          variant="danger"
          v-if="(!row.item.ApplicationNo) && (row.item.ApplicationStatusID == 0 || row.item.ApplicationStatusID == 1 || row.item.ApplicationStatusID == 2 || row.item.ApplicationStatusID == 4)"

        >
         Log History
        </b-button>
      </template>
      
      <template v-slot:cell(Gender)="row">
        <!-- <template slot="selected" slot-scope="row"> -->
        <template v-if="row.item.Gender">
          <span>Male</span>
        </template>
        <template v-else>
          <span>Female</span>
        </template>
      </template>
      <template v-slot:cell(ExServiceMan)="row">
        <!-- <template slot="selected" slot-scope="row"> -->
        <template v-if="row.item.ExServiceMan">
          <span>Yes</span>
        </template>
        <template v-else>
          <span></span>
        </template>
      </template>
      <template v-slot:cell(editDoc)="row">
        <b-button
          size="sm"
          @click.stop="editDocument(row.item)"
          class="mr-1 pt-0 pb-0"
          variant="warning"
          v-b-tooltip.hover
          title="Edit Doc"
          v-if="isAdmin"
          >Docs</b-button
        >
      </template>
    </b-table>
    <b-row>
      <b-col md="8" class="my-1">
        <b-pagination 
          :total-rows="totalRows"
          :per-page="perPage"
          v-model="currentPage"
          class="my-0"
          size="sm"
          @change="ChangeEvent"
        />
      </b-col>
      <b-col md="4" class="my-1">
        <b-form-group
          horizontal
          label="Per page"
          class="mb-0"
          label-size="sm"
          :label-cols="6"
        >
          <b-form-select
            :options="pageOptions"
            v-model="perPage"
            size="sm"
            @change="ChangeEventPage"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import apis from "@/apis";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import lambda from "@/apis/lambda";
import * as _ from "lodash";
import axios from "axios";
import Spinner from "vue-simple-spinner";
import Formatter from "@/Formatter";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import XLSX from "xlsx";
import DatePicker from "vue2-datepicker";
import ViewProfileFilter from "@/components/ViewProfileFilter.vue";
import MarksEntry from "@/components/MarksEntry.vue";

export default {
  props: ["userBoardID",  "userPoolType"],
  components: { Spinner, ViewProfileFilter, MarksEntry, DatePicker, Loading },
  data() {
    return {
      eventid:[],
      new_datees:"",
      EmployerRegNumber:"",
      DOB: '',
      PrincipalEmployerName:"",
      FirstName:"",
      EmailID:"",
      EmployerName:"",
      gender:"",
      nationality: null,
      petitiondate:"",
      PetitionNum:null,
      Orderdate:"",
      PetitionDate:null,
      partyName:"",
      AdharNo:"",
      CourtName:"",
      registrationno:"",
      employername:"",
      courtname:"",
      DeceasedName:"",
      DeceasedRegNum:"",
      DeceasedRelation:"",
      FirstLoginBeforeDate:"",
      FirstName:"",
      Gender:"",
      UnionName:"",
       HeirFirstName:"",
      HeirLastName:"",
      HeirMiddleName:"",
      LastName:"",
      MiddleInitial:"",
      Mobile:"",
      Nationality:"",
      OrderDate:'',
      OrderNum:"",
      ordernum:"",
      partyname:"",
      OtherRelationName:null,
      Mobile: "",
      email_id:"",
      adhar:"",
      UserID:"",
      LoginUserID:"",
      LoginID:"",
      newdue_date:"",
      showBtn: false,
      fullPage: false,
      Gender: "",
      show: false,
      showExtend: false,
      modalInfo: "",
      boards: this.$store.getters.getBoards,
      events: this.$store.getters.getEvents,
      crumbs: ["Home", "Category", "Sub category"],
      App: "App",
      Gen: "Gen",
      name: "",
      download_applications: [],
      approvalStatus: this.$store.getters.getApprovalStatus,
      applications: [],
      bscReport: "",
      Applications_name: [{ name: "Applications" }],
      bscReportPath: "",
      appCount: 0,
      appDate: null,
      user_id:"",
       list:[
        "Son",
        "Daughter",
        "Wife",
        "Husband",
        "Brother",
        "Sister",
        "Other(describe)"
      

      ],
      fields: [
        { key: "sr_no", label: "SrNo" },
        { key: "EventTitle", label: "Pool Type" },
        
        {
          key: "Createdate",
          label: "Profile Date",
          formatter: "convertDateFormat",
        },
        { key: "FullName", label: "Applicant Name" },
        // { key: "Gender", label: "Gender" },
        { key: "Mobile", label: "Mobile No." },
        { key: "PrincipalEmployerName", label: "Employer Name" },
        { key: "editapp", label: "Application Edit" },
        { key: "action1", label: "Due Date Edit" },
        { key: "loginAction", label: "Resend Email" },
        { key: "logAction", label: "Log History" },
      ],
      selected: [],
      form: {
       
        BoardID: "",
        EventID: "00000000-0000-0000-0000-000000000000",
        MobileNo: "",
        OffSet: 0,
        Limit: 500,
        RegType: 0,
        EmployerName: "",
        ApplicantName: "",
       
      },
      selectedApplication: "",
      selectedGender: "",
      selectedAge: 0,
      currentPage: 1,
      perPage: 5,
      totalRows: 0,
      pageOptions: [5, 10, 20, 50, 100, 200, 500, 1000],
      sortBy: null,
      sortDesc: false,
      filter: null,
      showDismissibleAlert: false,
      variant: "success",
      alertText: "",
      isLoading: false,
      isAdmin: false,
      title: "",
      old_form:[],
      old_log_form:[]
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    userBoard() {
      return this.$store.getters.getUserBoard;
    },
  },
  methods: {
        async downloadExcel() {
      var columns = [
        "Sr No",
        "Pool Type",
        "Profile Date",
        "Applicant Name",
        "Mobile No",
        "Employer Name"


       
      ];

      let app_data = [];

      await this.applications.forEach((element) => {
        let obj = {
           "Sr No": "",
           "Pool Type" : "",
          "Profile Date" : "",
          "Applicant Name" :"",
          "Mobile No" : "",
          "Employer Name": "",


        };
        obj["Sr No"] = element.sr_no;
        obj["Pool Type"] = element.EventTitle;
        obj["Profile Date"] = this.convertDateFormat(element.Createdate);
        obj["Applicant Name"] = element.FullName;
        obj["Mobile No"] = element.Mobile;
        obj["Employer Name"] = element.PrincipalEmployerName;
        app_data.push(obj);
      });

      var vc = this;
      var wb = XLSX.utils.book_new();
      var ws = XLSX.utils.json_to_sheet(app_data, { header: columns });
      ws["!cols"] = [
        { wpx: 125 },
        { wpx: 180 },
        { wpx: 120 },
        { wpx: 105 },
        { wpx: 105 },
        { wpx: 220 },
        { wpx: 90 },
        { wpx: 70 },
        { wpx: 70 },
      ];
      XLSX.utils.book_append_sheet(wb, ws, "Applications");
      XLSX.writeFile(wb, "Application.xlsx");
    },
   convertToPdf() {
    var totalRows = this.totalRows?this.totalRows.toString():'0';
      var pdf = new jsPDF("l", "pt");
      var columns = [
        { dataKey: "sr_no", title: "Sr No" },
        { dataKey: "EventTitle", title: "Pool Type" },
        { dataKey: "Createdate", title: "Profile Date" },
        {
          dataKey: "FullName",
          title: "Applicant Name",
        },
        { dataKey: "Mobile", title: "Mobile No" },
        { dataKey: "PrincipalEmployerName", title: "Employer Name" },
      
      ];

      var boardname = "";
      this.$store.getters.getBoards.forEach((item) => {
        if (
          item.SecurityBoardID == this.$store.getters.getAppSearchFormPvt.BoardID
        ) {
          boardname = item.SecurityBoardName;
        }
      });
      var ref = this;
      var pageContent = function (data) {
         pdf.text(
          "Security Guards Board for Brihan Mumbai & Thane District " ,
          pdf.internal.pageSize.getWidth() / 2,
          40,
          "center"
        );
        //pdf.text(boardname, pdf.internal.pageSize.getWidth() / 2, 25, "center");
        pdf.text(
          "Application List ",
          pdf.internal.pageSize.getWidth() / 2,
          55,
          "center"
        );
        pdf.setFontSize(12);
        pdf.text("Date :", 35, 70);
        // pdf.text("Total Applications :", 610, 70);
        pdf.text(ref.convertDateFormat(new Date()), 70, 70);
        // pdf.text(totalRows,720, 70);
        pdf.page = 1;
        function footer() {
          pdf.text(
            "page " + pdf.page,
            pdf.internal.pageSize.getWidth() / 2,
            pdf.internal.pageSize.height - 10
          );
          pdf.page++;
        }
      };

      var start_y_pos = 80;
      var vc = this;
      pdf.autoTable(
        columns,
        vc.applications,
        {
          didDrawPage: pageContent,
          startY: start_y_pos,
          margin: { top: 80 },
        },
        
      );
      pdf.save("Applications.pdf");
    },
    ResendEmail(data) {
    this.LoginID = data.LoginID;
      this.$root.$emit("bv::show::modal", "confiramation_model");
    },
    View_log_history(item){
      
      this.LoginID = item.LoginID;
      let promiseU = apis.LogDetails({UserId:item.LoginID}); 
     
      promiseU
      .then((response) => {
         this.isLoading=false;
        if (response.status == 200) {
            this.old_log_form = response.body;
            if ( this.old_log_form.length>0) {
               this.$root.$emit("bv::show::modal", "confiramation_log_model");
            }else{
              alert("Data Not Found");
            }
            
        } else {
          alert("Data Not Found")
        }
      })  
    },
    CancelModel(){
      this.$root.$emit("bv::hide::modal", "confiramation_model");
    },
    SendMail(){
      var payload = { ID: "00000000-0000-0000-0000-000000000000" ,UserID:this.LoginID };
      let promise = apis.Resendlogin(payload);

      promise
        .then((response) => {
          if (response.status == 200) {
            this.$dialog.alert("Email Resend Successfully");
               this.$root.$emit("bv::hide::modal", "confiramation_model");
              this.$router.push({
                  name: "RegistrationList",
                  params: {
                  BoardID: this.RecordDetails.BoardID, 
                  },
                });
           
          } else {
            this.$dialog.alert("ERROR" + response.body);
          }
        })
        
    },
    calculateAge(birthDate) {
    
      birthDate = new Date(birthDate);
      let incrementDate = new Date(birthDate);
       console.log(incrementDate);
      let today = new Date();
      let n = 0;
      while (incrementDate < today) {
        n++;
        incrementDate.setYear(incrementDate.getFullYear() + 1);
      }
      return n - 1;

    },
  
       validateAge() {
       let age = this.calculateAge(this.DOB);
         if (age < 18 || age > 58) {
          alert("Age Criteria not fulfilled");
          this.DOB = '';
          return false;
      }
},
      editApplication(item){
   
      this.LoginID = item.LoginID;
      let promiseU = apis.readappprofile({ Id: item.EventID,UserId:item.LoginID}); 
      this.eventid = item.EventID;
      promiseU
      .then((response) => {
         this.isLoading=false;
        if (response.status == 200) {
         
            this.old_form = response.body[0];
            this.PrincipalEmployerName = response.body[0].PrincipalEmployerName;
            this.EmployerRegNumber = response.body[0].EmployerRegNumber;
            this.FirstName = response.body[0].FirstName;
            this.MiddleInitial = response.body[0].MiddleInitial;
            this.LastName = response.body[0].LastName;
            this.DOB = new Date(response.body[0].DOB);
            this.Mobile = response.body[0].Mobile;
            this.EmailID = response.body[0].EmailID;
            this.AdharNo = response.body[0].AdharNo;
            this.Gender = response.body[0].Gender;
            this.Nationality = response.body[0].Nationality
            this.DeceasedRegNum = response.body[0].DeceasedRegNum;
            this.DeceasedName = response.body[0].DeceasedName;
            this.DeceasedRelation = response.body[0].DeceasedRelation;
            this.HeirFirstName = response.body[0].HeirFirstName;
            this.HeirMiddleName = response.body[0].HeirMiddleName;
            this.HeirLastName = response.body[0].HeirLastName;
            this.OrderNum = response.body[0].OrderNum;
            this.CourtName = response.body[0].CourtName;
            this.OrderDate = new Date(response.body[0].OrderDate);
            this.PetitionDate = new Date(response.body[0].PetitionDate);
            this.UnionName = response.body[0].UnionName; 
            this.$root.$emit("bv::show::modal", "mdlApp"); 
        } else {
          alert(response.body)
        }
      })  
    },
     convertDateFormatYMDWithTime(inDate) {
      if (inDate) {
        return Formatter.convertDateFormatYMDWithTime(inDate);
      }
    },
     convertDateFormatYMD1 (inDate) {
      if (inDate) {
        return Formatter.convertDateFormatYMD1 (inDate);
      }
    },
    convertDateFormat(inDate) {
      return Formatter.convertDateFormat(inDate);
    },
       nationality_h(Nationality) {
      if (Nationality) {
        return "Indian";
      } else {
        return "Nepali";
      }
    },

    onRowSelected(items) {
      this.selected = items;
    },

    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },

    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },


    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    disableFutureDates(date) {
      // Disable dates in the future
      return this.old_form.FirstLoginBeforeDate > new Date();
    },
      editDate(item){
      this.LoginID = item.LoginID;
      let promiseU = apis.readappprofile({ UserId:this.LoginID}); 
      
      promiseU
      .then((response) => {
         this.isLoading=false;
        if (response.status == 200) {
          if (response.body.length>0) {
            this.old_form = response.body[0]; 
            if (response.body[0].FirstLoginBeforeDate == null) {
             this.newdue_date = new Date();
            }   
            else
            {
               this.newdue_date = new Date(response.body[0].FirstLoginBeforeDate);
            }
            this.$root.$emit("bv::show::modal", "editdate");
          }else{
            alert('Data Not Found');
          }
        } else {
          alert(response.body)
        }
      })
    },
    onSubmit() {
      // if (this.appDate != null) {
      //   this.form.StartDate = Formatter.convertDateFormatYMD(this.appDate[0]);
      //   this.form.EndDate = Formatter.convertDateFormatYMD(this.appDate[1]);
      // } else {
      //   this.form.StartDate = "0001-01-01";
      //   this.form.EndDate = "0001-01-01";
      // }
      this.currentPage = 1;
      this.form.Limit = this.perPage;
      // this.form.Limit = 500;
      this.form.OffSet = 0;

      this.getProfilesSub();
    },

    getProfiles() {
      if (this.userBoard != "00000000-0000-0000-0000-000000000000") {
        this.form.BoardID = this.userBoard;
      }

      this.perPage = this.form.Limit;
      // this.perPage =500;
      this.currentPage = this.form.OffSet / this.perPage + 1;

      this.getProfilesSub();
    },

    ChangeEvent(evt) {
      this.currentPage = evt;
      this.form.Limit = this.perPage;
      this.form.OffSet = (this.currentPage - 1) * this.perPage;
      this.getProfilesSub();
    },

    ChangeEventPage(evt) {
      this.perPage = evt;
      this.currentPage = 1;
      this.form.Limit = this.perPage;
      this.form.OffSet = 0;
      this.getProfilesSub(); 
    },
     convertDateFormatYMDY(inDate) {
      if (inDate) {
        return Formatter.convertDateFormatYMDY(inDate);
      }
    },
      convertDateFormatYMD(inDate) {
      if (inDate) {
        return Formatter.convertDateFormatYMD(inDate);
      }
    },
    Submit_profile(){
       
       if (!this.DOB) {
        alert("Please Select DOB");
        return;
      }

       if (!this.OrderDate) {
        alert("Please Select Order Date");
        return;
      }
       var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

      if (this.EmailID !== "" && this.EmailID != null) {
        if (reg.test(this.EmailID) == false) {
          alert("please Enter Valid Email ID");
          return false;
        }
      }
      
      var data = {
        UserID: this.LoginID,
        LoginUserID: this.user_id,
        FirstName: this.FirstName,
        MiddleInitial: this.MiddleInitial,
        LastName: this.LastName,
        PrincipalEmployerName: this.PrincipalEmployerName,
        EmployerRegNumber: this.EmployerRegNumber,
        CourtName: this.CourtName,
        DeceasedName: this.DeceasedName,
        DeceasedRegNum: this.DeceasedRegNum,
        DeceasedRelation: this.DeceasedRelation,
        DOB: this.convertDateFormatYMDY(this.DOB),
        Mobile: this.Mobile,
        EmailID: this.EmailID,
        AdharNo: this.AdharNo,
        Gender: this.Gender,
        HeirFirstName: this.HeirFirstName,
        HeirMiddleName: this.HeirMiddleName,
        HeirLastName: this.HeirLastName,
        Nationality:  this.Nationality,
        OrderDate: this.convertDateFormatYMDY(this.OrderDate),
        OrderNum: this.OrderNum, 
        UnionName: this.UnionName,
        OtherRelationName: this.OtherRelationName,
        PetitionDate: this.convertDateFormatYMDY(this.PetitionDate),
        PetitionNum: this.PetitionNum
      };
      this.isLoading=true;
      let promise = apis.updateUserDetails(data);
      promise
        .then((response) => {
          this.isLoading=false;
          if (response.status == 200) {
          this.$dialog.alert("Save Succesfully..");
          this.getProfilesSub();
         
          } else {
            this.$dialog.alert(" UnSuccesfully..");
          }
          this.$root.$emit("bv::hide::modal", "mdlApp");
           
        })
        .catch((err) => {
          this.alertText = err.message;
          this.showDismissibleAlert = true;
          this.variant = "danger";
          this.isLoading = false;
        });
    },
    calculate_Days(from_date,to_date){
      const date1 = new Date(from_date); // Replace with your first date
      const date2 = new Date(to_date); // Replace with your second date
      const timeDifference = Math.abs(date2 - date1);

      // Calculate the number of days by dividing milliseconds by the number of milliseconds in a day
      const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
      return daysDifference;
    },
     Submit_Date(){ 
     var days = this.calculate_Days(new Date(),this.newdue_date);
      if (days>=30) {
        alert("Invalid Date");
        return false;
     }
      
      if (!this.newdue_date)
       {
          alert("Please Select New Due Date");
         return;
      }
      // if (this.old_form.FirstLoginBeforeDate <= this.new_datees ) {
      //   alert("Due date not correct");
      //   return;
      // }
      var data = {
        UserID: this.LoginID,
        ValidTillDate: this.convertDateFormatYMD(this.newdue_date),
        LoginUserID: this.user_id
      };
      this.isLoading=true;
      let promise = apis.updateDueDate(data);
      promise
        .then((response) => {
          this.isLoading=false;
          if (response.status == 200) {
          this.$dialog.alert("Save Succesfully..");
          } else {
            this.$dialog.alert(" UnSuccesfully..");
          }
          this.$root.$emit("bv::hide::modal", "editdate");
        })
        .catch((err) => {
          this.alertText = err.message;
          this.showDismissibleAlert = true;
          this.variant = "danger";
          this.isLoading = false;
        });
    },
    getProfilesSub() {
      this.isLoading = true;
      // this.form.ApplicationStatus = JSON.parse(
      //   JSON.stringify(this.form.selectValue)
      // );
      this.showDismissibleAlert = false;

      //console.log(this.form.ApplicationStatus.length);
      // if (
      //   this.form.ApplicationStatus &&
      //   this.form.ApplicationStatus.length == 0
      // ) 
      // {
      //   for (let x = 0; x < this.approvalStatus.length; x++) {
      //     this.form.ApplicationStatus.push(this.approvalStatus[x]);
      //   }
      // }
     

      this.$store.dispatch("setSearchForm", this.form);
      let promise = apis.ViewProfileList(this.form);

      promise
        .then((response) => {
          //console.log(response);
          this.isLoading = false;
          if (response.status == 200) {
              this.applications = response.body.Table1;
            for (var i = 0; i < this.applications.length; i++) {
              this.applications[i].sr_no = i + 1;
            }
            this.totalRows = response.body.Table[0].TotalRecords;
            var userinfo = this.$store.getters.loggedUser;
          } else {
            this.alertText = response.body;

            this.variant = "warning";
            this.showDismissibleAlert = true;
          }
        })
        .catch((err) => {
          this.alertText = err.message;
          this.showDismissibleAlert = true;
          this.variant = "danger";
          this.isLoading = false;
        });

    },

    onReset(evt) {
      evt.preventDefault();
      this.form = {
        BoardID: this.userBoard,
        EventID: "00000000-0000-0000-0000-000000000000",
        MobileNo: "",
        Offset : "",
        Limit: "",
        EmployerName : "",
        RegType :0,
        ApplicantName :"",
      
      };
    },

   
 

    gender_h(gender) {
      if (gender) {
        return "Male";
      } else {
        return "Female";
      }
    },
    service_man(service) {
      if (service) {
        return "Yes";
      } else {
        return "No";
      }
    },
  },

  mounted() {
    this.user_id = this.$store.getters.loggedUser.UserId;
    this.form.BoardID = this.userBoard;
    let frm = this.$store.getters.getAppSearchForm;

    // if (frm != {} && frm.ApplicationStatus != undefined) {
    //   this.form = frm;
    //   this.getProfiles();
    // }
  },
};
</script>

<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
